import '../dist/checkout-v2.css'
import '../blueprints/checkout-configuration-error-v1.ts'
import '../blueprints/checkout-tos-v1.ts'
import '../blueprints/checkout-coupon-form-v1.ts'
import '../blueprints/checkout-order-summary-v1.ts'
import '../blueprints/checkout-multi-step-v2.ts'
import '../blueprints/checkout-product-card-v2.ts'
import '../blueprints/checkout-product-card-bump-v2.ts'
import '../blueprints/checkout-product-select-v2.ts'
import '../blueprints/checkout-product-select-bump-v2.ts'
import '../blueprints/checkout-shipping-profile-select-v1.ts'
import '../blueprints/checkout-loading-v1.ts'
import '../blueprints/checkout-order-details-content-v1.ts'
import '../blueprints/checkout-order-details-v1.ts'
import '../blueprints/checkout-submit-button-v1.ts'
import '../blueprints/checkout-v2.ts'
